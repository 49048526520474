.scroller-card {
    background-color: inherit;
    min-width: 300px;
    max-width: 1170px;
    width: 30%;
    cursor: pointer;
}
.scroller {
    height: 2rem;
    position: relative;
    overflow: hidden;
}
.scroller > span {
    position: absolute;
    top: 0;
    animation: slide 5s infinite;
    font-weight: bold;
    color: rgb(60, 72, 82);
    font-size: 2rem;
    font-family: "Ubuntu";
}
@keyframes slide {
    0% {
        top: 0;
    }
    25% {
        top: -1.2em;
    }
    50% {
        top: -2.4em;
    }
    75% {
        top: -3.6em;
    }
}
.Course-content {
    display: grid;
    gap: 3rem;
    padding: 3rem 0 3rem 3rem;
    background-image: url("../Assets/images/Courses/main_home.png");
    background-repeat: no-repeat;
    background-position: right;
    /* background-size: fixed; */
}

.Course-content > p {
    font-weight: 700;
    font-size: 1.2rem;
    color: rgb(60, 72, 82);
}
.Course-content > button {
    width: fit-content;
    color: #ffffff;
    background: #00b0f0;
    border-color: #00b0f0;
    font-size: 0.8rem;
    padding: 1rem 1rem;
    border-radius: 1.5rem;
    outline: none;
    border: none;
    font-weight: 600;
    border: 1px solid #00b0f0;
}
.Course-content > button:hover {
    background-color: white;
    color: #00b0f0;
    border: 1px solid #00b0f0;
    cursor: pointer;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.heading {
    max-width: 26rem;
    width: 100%;
    display: grid;
    gap: 0;
    grid-template-columns: 0.45fr 0.48fr;
    gap: 0;
}
.heading h3 {
    color: #435c77;
    font-size: 1rem;
    font-style: "ubuntu";
    font-weight: bold;
}
.heading p {
    font-weight: bold;
    font-style: "ubuntu";
    font-weight: 1rem;
    line-height: 1.5rem;
    color: #ff7f3c;
    /* width: 60%; */
}
.bg_image {
    display: none;
}

.all-subjects {
    background-color: #f3f3f3;
    padding: 3rem;
    display: grid;
    gap: 2.5rem;
}
.all-subjects > h3 {
    text-align: center;
    font-family: "Ubuntu";
    font-size: 2.1rem;
}
@media screen and (max-width: 1100px) {
    .Course-content {
        background-image: none;
    }
    .bg_image {
        display: block;
        width: 100%;
    }
    .bg_image img {
        width: 100%;
        min-height: 100%;
    }
    .Course-content > img {
        max-width: 25rem;
    }
}
@media screen and (max-width: 767px) {
    .Course-content {
        padding: 1.5rem;
    }
    .Course-content > img {
        width: 15rem;
    }
    .Course-content > button {
        width: 100%;
    }
    .heading {
        justify-items: center;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .heading p {
        width: 58%;
        text-align: center;
    }
    .all-subjects > h3 {
        font-size: 1.2rem;
        text-align: left;
    }
}
/* ------all courses---- */
.coursesData {
    display: grid !important;
    gap: 2rem !important;
}
.coursesData > h3 {
    text-shadow: 0 0 2px #000;
    font-size: 25px;
    border-left: #00b0f0 10px solid;
    padding: 10px;
    background-color: rgba(0, 176, 240, 0.1);
}
.subject-item {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    cursor: pointer;
    display: grid !important;
    gap: 0.5rem !important;
    max-width: 300px !important;
    /* width: 80%; */

    padding-bottom: 2rem;
}
.subject-item > img {
    background-color: #73726c;
    width: 100%;
    height: 135px;
}
.subject-item > h2 {
    margin-left: 1rem;
    font-family: "Ubuntu";
    font-weight: 700;
    line-height: 1.2;
    font-size: 0.9rem;
}
.subject-item > p {
    margin-left: 1rem;
    color: #73726c;
    margin-top: 1rem;
    font-size: 0.8rem;
}
.rating-wrap,
.rating {
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.rating-wrap {
    gap: 0.2rem;
}
.ratingcount {
    margin: 0;
    padding: 0;
    align-self: flex-start;
    color: #73726c;
    font-weight: 400;
    font-size: 1rem;
}
.rate {
    align-self: flex-start;
    font-size: 1.2rem;
    color: #be5a0e;
    font-weight: 700;
}
.bestSeller {
    background-color: #ffe799;
    color: #593d00;
    width: fit-content;
    padding: 0.5rem;
    font-weight: bold;
    font-size: 0.8rem;
    border-radius: 4px;
}
.slider {
    overflow: hidden;
    width: 90vw;
    height: 100%;
    padding-bottom: 1rem;
}
.slick-tract {
    margin: none !important;
}
.allsubjects {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

    gap: 1rem;

    row-gap: 3rem;
}
/* @media screen and (max-width: 1334px) {
    .subject-item > img {
        width: 250px;
    }
} */
@media screen and (max-width: 900) {
    .allsubjects {
        justify-items: center;
    }
}
@media screen and (max-width: 767px) {
    .coursesData > h3 {
        font-size: 1.2rem;
    }
}
@media screen and (max-width: 500px) {
    .allsubjects {
        grid-template-columns: 1fr;
        justify-items: center;
    }
}
/* -----------courses subnav navbar---- */
.CoursesubNav {
    min-height: 15rem;
    padding-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.CoursesubNav button {
    background-color: white;
    border: none;
    display: grid;
    gap: 0.5rem;
    justify-items: center;
    cursor: pointer;
    width: 25%;
    padding: 2rem 0;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.CoursesubNav button:hover {
    border-bottom: 5px solid #00b0f0;
}
.CoursesubNav img {
    width: 60px;
    height: 60px;
}
.CoursesubNav .active {
    position: relative;
    padding: 3rem 0;
    /* width: 30%; */
    transition: 500ms;
    border-bottom: 5px solid #00b0f0;
}

@media screen and (max-width: 767px) {
    .CoursesubNav button {
        width: 32%;
    }
    .CoursesubNav button img {
        width: 40px;
        height: 40px;
    }
}
.mentorship-wrapper {
    background-image: url("../Assets/images/Courses/mentor_left.png"),
        url("../Assets/images/Courses/mentor_right.png");
    background-position: top left, bottom right;
    background-size: 30%, 30%;
    background-repeat: no-repeat, no-repeat;
    padding: 4rem 0;
    margin: 1rem 2rem;
    border-top: 1px solid rgb(242, 242, 242);
}
.mentorship {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15rem;
}
.mentorship img {
    width: 400px;
}
.mentorship .mentor-button {
    display: grid;
    gap: 4rem;
}
.mentorship .mentor-button {
    align-self: flex-start;
    padding-top: 3rem;
}
.mentorship .mentor-button p {
    line-height: 1.5;
    font-weight: 500;
    color: black;
    text-transform: capitalize;
    text-align: center;
    font-size: 27px;
}
.mentorship .mentor-button p span {
    font-weight: 700 !important;
}
.mentorship .mentor-button > button {
    justify-self: center;
    width: fit-content;
    color: #ffffff;
    background: #00aeb5;
    border-color: #00aeb5;
    font-size: 1.2rem;
    padding: 0.7rem 2rem;
    border-radius: 1.5rem;
    outline: none;
    border: none;
    font-weight: 600;
    border: 3px solid #00aeb5;
}
.mentorship .mentor-button > button:hover {
    background-color: white;
    color: #00aeb5;
    border: 3px solid #00aeb5;
    cursor: pointer;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
@media screen and (max-width: 977px) {
    .mentorship {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        gap: 2rem;
    }
}
@media screen and (max-width: 977px) {
    .mentorship {
        grid-template-columns: 1fr;
        gap: 0;
    }
    .mentorship > img {
        width: 350px;
    }
}
.questionPaper-header h3 {
    font-size: 2.5rem;
    color: #000000;
    margin-top: 21px;
    opacity: 0.8;
    line-height: 50px;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    font-style: normal;
}
.questionPaper-header p {
    font-size: 1.3rem;
    color: #435c77;
    line-height: 28px;
    text-align: center;
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
}
.paper-error {
    color: red;
    border: 1px solid red;
}
.questionpaper-wrapper {
    padding: 5rem 0;
    display: grid;
    gap: 2rem;
}
.question-paper-form {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 2rem;
}
.question-paper-form .question-paper-control {
    display: flex;
    gap: 2rem;
}
.question-paper-form form {
    display: grid;
    gap: 2.5rem;
}
.question-paper-form form select {
    height: 34px;
    padding: 0 1rem;
    width: 20rem;
    font-size: 1.3rem;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.question-paper-form form select:focus {
    border: 1px solid #00aeb5;
}
.question-paper-form form button {
    width: fit-content;
    justify-self: center;
    color: white;
    background-color: #00b0f0;
    border: none;
    font-size: 1.3rem;
    padding: 0.5rem 2rem;
    border-radius: 10px;
    cursor: pointer;
}
.question-paper-form form .submit {
    padding: 1rem 6rem;
    font-size: 1.5rem;
}
@media screen and (max-width: 1200px) {
    .question-paper-form form select {
        width: 100%;
    }
}
@media screen and (max-width: 500px) {
    .question-paper-form .question-paper-control {
        flex-direction: column;
    }
}
/* -------course-footer----- */
.footer-wrapper {
    background: linear-gradient(
        45deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(98, 98, 98, 1) 100%
    );
    display: grid;
    align-items: center;
    gap: 1.5rem;
    justify-items: center;
    padding: 1.5rem 0;
}

.footer-wrapper > a {
    text-decoration: none;
    background: black;
    width: fit-content;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-family: "Ubuntu";
    font-style: normal;
    letter-spacing: 0.02em;
    color: #9e9e9e;
    border: none;
    cursor: pointer;
    padding: 1rem 2rem;
}
.footer-wrapper > a:hover {
    color: grey;
}
.footer-wrapper > a p {
    font-weight: 400;
    font-size: 1rem;
}
.footer-wrapper > a h5 {
    font-weight: 700;
    font-size: 1.4rem;
}
.footer-wrapper > h4 {
    font-size: 1.8rem;
    text-align: center;
    font-weight: 550;
    font-style: normal;
    color: #fff;
}
.footer-wrapper > p {
    font-size: 0.8rem;
    text-align: center;
    font-weight: 700;
    font-style: normal;
    color: #fff;
}
.footnav {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-items: center;
}
.footnav a {
    color: #9e9e9e;
    text-decoration: none;
    border-right: 2px solid #73726c;
    padding-right: 0.3rem;
    margin: 0;
}
.footnav a:hover {
    color: grey;
}
@media screen and (max-width: 768px) {
    .footer-wrapper > a {
        padding: 0.5rem 1rem;
    }
    .footer-wrapper > p {
        font-size: 0.8rem;
    }
    .footnav a {
        font-size: 0.8rem;
        /* padding: 0.2rem; */
        /* border: none; */
    }
}
/* ---------coursemodal ----------- */

.course-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10000;
}
.course-modal-content {
    gap: 1rem;
    opacity: 1;
    max-width: fit-content;

    width: 100%;
    /* margin-top: 7%;
    margin-left: 23%; */
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    position: relative;
    /* padding: 4rem 2rem; */
    padding: 2rem 1rem 2rem 1rem;
}
.course-modal-content h3 {
    text-overflow: ellipsis;
    white-space: normal;
    font-size: 1.2rem;
    font-weight: 600;

    color: #505763;
}
.course-modal-content h4 {
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
}
.course-modal-content > h5 {
    color: #f6892a;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    font-size: 15px;
}
.course-modal-content p {
    display: -webkit-box;
    max-width: 550px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* text-align: justify; */
    font-size: 1.1rem;
    line-height: 1.53;
    color: #686f7a;
}
.course-modal-content button {
    background-color: #f6892a;
    color: white;
    text-transform: capitalize;
    border: none;
    padding: 0.7rem 0;
    font-size: 1.1rem;
    font-weight: 600;
    width: 80%;
    cursor: pointer;
}
/* -------------COURSE VIDEO--------- */
.videoContentWrapper {
    padding: 3rem 1rem 1rem 4rem;
    display: grid;
    grid-template-columns: 0.6fr 0.4fr;
    gap: 2rem;
}
.videoplayer {
    display: grid;
    gap: 0;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    /* box-shadow: ; */
}
.videocontent-Leftside {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.videoplayer p {
    text-align: right;
    background: #4593e3;
    color: white;
    padding: 1rem 2rem 3rem 0;
    font-family: "Inconsolata", monospace;
    font-weight: 900;
    font-size: 18px;
    margin: 0;
    display: inline-block;
    color: #ffffff;
    text-shadow: 0 0 2px #000;
    vertical-align: middle;
}
.video-Data {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    display: grid;
    gap: 2rem;
    padding: 2rem 1rem;
}
.video-Data > h3 {
    font-size: 24px;
    margin: 0;
    line-height: 1.3;
    font-weight: 400 !important;
    word-break: break-all;
}
.video-Data > h5 {
    word-wrap: break-word;
    font-size: 1rem;
    line-height: 21px;
    color: #777;
    font-weight: 400;
}
.videoRating {
    display: flex;
    gap: 0.3rem;
    align-items: flex-start;
}
.videoRating > p {
    line-height: 1.4rem;
    color: #be5a0e;
    font-weight: 600;
    font-size: 1.2rem;
}
.purchase {
    display: grid;
    padding: 0 1rem;
    gap: 2rem;
}
.purchase p {
    text-align: center;
    text-transform: capitalize;
    font-family: Retina, Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 25px;
    font-weight: 700;
    color: rgb(83 82 82 / 80%);
}
.purchase p span {
    text-transform: capitalize;
}
.purchase p del {
    font-size: 13px;
}
.buy-btns {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    /* justify-self: center; */
}
.buy-btns a {
    text-decoration: none;
    background: #216fbd;
    padding: 10px 4rem;
    color: white;
    font-size: 0.9rem;
    /* font-family: Retina, Arial, Helvetica, sans-serif; */
    font-weight: 600;
    border: none;
    cursor: pointer;
}
.videonavbar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    /* height: 5rem; */
    text-align: center;
    /* justify-items: center; */
}
.videonavbar button {
    background-color: white;

    display: grid;
    gap: 0.5rem;
    justify-items: center;
    cursor: pointer;
    padding: 2rem 0;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.inactivevideo {
    border: none;
}
.videonavbar button:hover {
    border-bottom: 5px solid #00b0f0;
}
.activevideo {
    border: none;
    border-bottom: 5px solid #00b0f0;
}
.Syllabus {
    padding: 2rem 0;
    display: grid;
    transition: 1s;
}
.Syllabus button {
    font-family: "Rubik", sans-serif;
    border: 1px solid #d0d0d0;
    color: #333;
    font-size: 16px;
    height: 3rem;
    font-weight: 700;
    background-color: white;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Syllabus button:hover {
    color: #428bca;
    transition: 500ms;
}
.downarrow {
    position: absolute;
    right: 10px;
}
.Syllabus p {
    background-color: black;
    color: white;
    padding: 1rem;
    font-size: 1.2rem;
    line-height: 25px;
    font-family: "Lora", serif;
    text-align: center;
}
.Syllabus button h3 {
    display: -webkit-box;
    /* width: 100%; */

    -webkit-line-clamp: 1;
    /* -webkit-box-orient: vertical; */
    overflow: hidden;
}
.upnext {
    display: grid;
    gap: 2rem;
    margin-top: 1rem;
}
.upnext-header {
    display: flex;
    justify-content: space-between;
}
.upnext-header > div {
    display: flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    margin: 5px 0 0;
    font-size: 18px;
}
.upnext-header select {
    width: 5rem;
    height: 2rem;
    text-indent: 0.5rem;
}
.videos {
    border-top: 1px solid #ebebeb;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: 1rem;
    width: 90%;
    margin: auto;
    padding: 1rem;
    cursor: pointer;
}
.video-item {
    display: flex;
    flex-direction: column;
    gap: 0;
    position: relative;
}
.video-item img {
    width: 99%;
}
.video-item:hover .playicon {
    display: block;
}
.video-item img:hover {
    filter: brightness(70%);
}
.video-item p {
    display: -webkit-box;
    max-width: 550px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 700;
    color: #333;
    font-size: 13px;
    background-color: #f0f0f0;
    text-align: center;
    width: 95%;
    height: 1.8rem;
    padding: 0.5rem 0.3rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.playicon {
    position: absolute;
    top: 30%;
    left: 50%;
    display: none;
}
.video_player {
    width: 100%;
    height: 500px;
}
@media screen and (max-width: 950px) {
    .videoContentWrapper {
        grid-template-columns: 1fr;
        gap: 3rem;
        padding: 0;
    }
    .upnext-header {
        padding: 1rem;
    }
}
@media screen and (max-width: 550px) {
    .videos {
        grid-template-columns: 1fr;
    }
    .video-item p,
    .video-item img {
        width: 100%;
    }
    .video-item p {
        padding: 2rem 0;
    }
    .video_player {
        height: 300px;
    }
    .video-Data > h3 {
        font-size: 1rem;
        word-break: keep-all;
    }
    .videoplayer p {
        text-align: center;
        padding: 2rem 0;
    }
    .buy-btns button {
        font-size: 12px;
        line-height: 1rem;
    }
}
