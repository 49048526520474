footer {
    background: #54215d;
}

.wrapper {
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
    gap: 2.5rem;
    /* margin: 3rem 1rem 5rem 0rem; */
    padding: 2rem 0 1rem 0;
}
.logo {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
}
/* .images {
 display: flex;
 flex-direction: column;
 background-color: white;
 width: fit-content;
 padding: 1rem;
 border-radius: 50%;
} */
.images img {
    width: 120px;
}
.logo p {
    width: 60%;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    /* or 140% */

    letter-spacing: 0.02em;

    color: #cccccc;
}
.nav_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.nav,
.download {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    gap: 1rem;
}
.download {
    align-items: center;
}
.nav h3 {
    margin-bottom: 1.5rem;
}
.nav h3,
.download h3 {
    justify-self: center;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    /* identical to box height, or 83% */
    color: white;
    letter-spacing: 0.05em;
}

.download img {
    width: 16rem;
    align-self: flex-end;
}
.nav a,
.usefull a {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    /* font-weight: 400; */
    font-size: 1.2rem;
    line-height: 34px;
    letter-spacing: 0.02em;
    text-decoration: none;
    color: rgba(204, 204, 204, 0.8);
}

.Copyright {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid rgba(255, 255, 255, 0.16);
    color: white;
    text-align: center;
    padding: 0.5rem 1rem;
    align-items: center;
}
.Copyright p {
    font-size: 1rem;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;

    color: rgba(255, 255, 255, 0.32);
}
.Copyright button {
    justify-self: center;
    background: white;
    border: none;
    color: black;
    border: 1.5px solid #004a4a;
    border-radius: 4px;
    width: 1.9rem;
    height: 1.9rem;
}
.usefull {
    display: flex;
    flex-direction: column;
    /* margin-top: 3rem; */
    gap: 1rem;
}
.iconswrap {
    width: 60%;
    display: flex;
    gap: 1rem;
    align-self: flex-start;
    justify-self: center;
    align-self: center;
    /* margin-left: 3rem; */
    align-items: center;
}
.social {
    color: white;
}
@media screen and (max-width: 1000px) {
    .download img {
        width: 8rem;
    }
}
@media screen and (max-width: 767px) {
    .logo p {
        width: 95%;
        text-align: justify;
    }
    .iconswrap {
        width: 95%;
    }
    .wrapper {
        grid-template-columns: 1fr;
        margin: 1rem;
        margin-top: 0;
    }

    .nav_wrapper {
        display: grid;
        grid-template-columns: 1fr;
        /* justify-items: center; */
        margin-left: 1rem;
        gap: 2rem;
    }
    .Copyright p {
        margin-left: 0;
    }
    .download {
        align-items: unset;
        width: 100%;
    }
    .download a {
        /* width: 15rem; */
        width: 100%;
    }
    .download a img {
        width: 80%;
    }
}
