.circle-loader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    animation: linear normal forwards;
    animation-name: run;
    animation-duration: 100ms;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: 100ms;
}
.loader {
    /* margin-left: 15rem; */
    display: grid;
    align-items: center;
    justify-items: center;
}
