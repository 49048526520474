.about-content {
    padding-bottom: 3rem;
    display: grid;
    gap: 2rem;
    padding: 3rem 6rem 4rem 6rem;
}
.about-content h3 {
    padding: 1rem 0;
    text-align: center;
    font-weight: 900;
    font-size: 35px;
    font-family: "Lato", sans-serif;
}
.about-content p {
    line-height: 25px;
    letter-spacing: 0.02em;
    font-family: "Lato", sans-serif;
}
@media screen and (max-width: 767px) {
    .about-content {
        padding: 3rem;
    }
    .about-content p {
        text-align: justify;
    }
}
