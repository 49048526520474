/* .............navBar------------- */
* {
    margin: 0;
    padding: 0;
}
.Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: #54215d;
}
.leftside {
    gap: 0.5rem;
    border-radius: 50%;
    /* margin-top: 1rem; */
    margin-left: 3rem;
    flex: 35% 1;
    justify-self: center;
    align-self: center;
}
.lefside_imgwrapper {
    cursor: pointer;
}
.lefside_imgwrapper img {
    width: 130px;
}

.rightside {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* flex: 65%; */
    gap: 2.5rem;
    margin: 1rem 0rem;
}
.links {
    display: none;
    gap: 2.5rem;
}
.links a {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
    font-size: 1.2rem;
    line-height: 34px;
    letter-spacing: 0.02em;
}

.buttonwrapper {
    display: flex;
    gap: 1rem;
}
.buttonwrapper > button {
    cursor: pointer;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 17px;
    letter-spacing: 0.02em;
}
.Signup {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 500;
    padding: 0.8rem 2rem;
    color: #00b8d9;
    border: none;
    /* color: linear-gradient(180deg, #00d9ce 0%, #00b8d9 100%); */
}
.buttonwrapper .Login {
    color: #ffffff;
    background: #54215d;
    color: #ffffff;
    padding: 0.7rem 1.5rem;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
    border-radius: 10px;
}
.active {
    color: #f55700;
    font-weight: 600;
}
.buttoninactive {
    color: white;
    border: none;
    background-color: inherit !important;
    cursor: pointer;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    font-size: 1.2rem;
    line-height: 34px;
    letter-spacing: 0.02em;
}
.buttonactive {
    color: #f55700;
    font-family: "Ubuntu", sans-serif;
    background-color: inherit;
    border: none;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
    font-size: 1.2rem;
    line-height: 34px;
    letter-spacing: 0.02em;
    font-weight: 600;
}
.inactive {
    color: white;
}
#hidden {
    position: absolute;
    left: 0px;
    top: 103px;
    max-height: 290px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    /* padding: 5px; */
    background: #301860;
    z-index: 20;
}
#hidden a {
    text-align: left;
    margin-left: 2.5rem;
}
#hidebutton {
    text-align: left;
    margin-left: 0.5rem;
    font-size: 1.2rem;
    background-color: inherit;
    border: none;
    color: white;
}
#hidebuttonwrap {
    justify-items: left;
}
.icon {
    color: inherit;
    border: none;
}
@media screen and (min-width: 1360px) {
    .Navbar .rightside .links {
        display: flex;
        justify-content: space-around;
        align-items: center;

        flex: 50%;
        max-height: 100px;
    }

    .button {
        display: none;
    }
    .userdetails {
        display: flex;
        align-self: center;
        justify-items: center;
        align-items: center;
        justify-self: center;
        gap: 1rem;
        cursor: pointer;
    }
    .Navbar .rightside .logged_in {
        justify-content: right;
        gap: 1.5rem;
    }
    .Navbar .rightside .logged_in .userdetails {
        margin-left: 0;
    }
}

@media screen and (max-width: 1360px) {
    .buttonactive,
    .buttoninactive {
        margin-left: 4rem;
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-family: "Ubuntu", sans-serif;
        font-style: normal;
        letter-spacing: 0.02em;

        border: none;
        cursor: pointer;
        padding: 0rem 2rem;
    }
    .leftside img {
        width: 100%;
        padding: 0;
    }
    .buttonwrapper {
        display: grid;
        gap: 0;
    }
    #hidebutton {
        font-size: 1.5rem;
    }
    .rightside {
        justify-content: right;
    }

    .leftside h1 {
        margin: 0;
    }
    .leftside {
        margin-left: 1rem;
    }
    .lefside_imgwrapper img {
        width: 65px;
    }
}
.userdetails p {
    color: white;
}
.userdetails img {
    width: 25%;
}
.userdetails {
    display: flex;
    margin-left: 2.5rem;
    font-size: 1.5rem;
    color: #bfb2b2;
}
.AboutWrapper {
    margin: 0;
    background-color: #54215d;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    letter-spacing: 0.02em;
    color: #ffffff;
    display: grid;
    gap: 2.3rem;
    padding-bottom: 10rem;
    background-image: url("../Assets/images/about_bg.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: fixed;
}

.AboutWrapper > h3 {
    font-weight: 500;
    font-size: 3rem;
    font-family: "Ubuntu";
    padding: 7rem 0 0rem 4rem;
    width: 45%;
    /* margin: 0; */
}
.AboutWrapper > p {
    padding: 0 0 0rem 4rem;
    width: 40%;
    font-weight: 400;
    font-size: 1.6rem;
}
.AboutWrapper a {
    text-decoration: none;
    margin-left: 4rem;
    background: #f55700;
    width: fit-content;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    letter-spacing: 0.02em;
    color: #ffffff;
    border: none;
    cursor: pointer;
    padding: 1rem 2rem;
}

.AboutWrapper a p {
    font-weight: 400;
    font-size: 0.9rem;
}
.AboutWrapper a h5 {
    font-weight: 700;
    font-size: 1.2rem;
}
@media screen and (min-width: 1700px) {
    .AboutWrapper {
        background-size: 130rem;
        background-position: right;
    }
}
@media screen and (max-width: 1300px) {
    .AboutWrapper {
        background-size: 80rem;
        background-position: right;
    }
}
@media screen and (max-width: 1200px) {
    .AboutWrapper {
        background-image: none;
    }
    .AboutWrapper > h3,
    .AboutWrapper p {
        width: 80%;
    }
}
@media screen and (max-width: 768px) {
    .AboutWrapper {
        justify-items: center;
        gap: 3rem;
        padding-bottom: 2rem;
    }
    .AboutWrapper > h3 {
        width: 90%;
        padding: 3rem 0 0 0;
        font-size: 2rem;
    }
    .AboutWrapper p {
        width: 80%;
        padding: 0;
        font-size: 1.3rem;
    }
    .AboutWrapper a {
        padding: 0.5rem 1rem;
        margin-left: 0;
    }
}

/* --------------why chooose us --------- */

.why_Choose_us_Wrapper {
    background-color: #fef8ed;
    padding: 6rem 0;
    display: grid;
    /* justify-items: center; */
    gap: 9rem;
}
.why_Choose_us_Wrapper header {
    display: grid;
    align-items: center;
    justify-items: center;
}
.why_Choose_us_Wrapper header h3 {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.3rem;
    text-align: center;
    color: #301860;
    z-index: 10;
}
.why_Choose_us_Wrapper header h3 img {
    position: absolute;
    /* top: -2rem; */
    bottom: -1.5rem;
    width: 90px;
    left: -2.5rem;
    z-index: -10;
}
.why-choose-us-cards {
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
    justify-items: center; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    z-index: 100;
    row-gap: 4rem;
}
.why-choose-us-card {
    background-color: #ffffff;
    /* width: 25rem; */
    width: fit-content;
    max-width: 23rem;
    height: 15rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    padding: 1rem;
    position: relative;
    border: 1px solid rgba(245, 87, 0, 0.5);
    box-sizing: border-box;
    box-shadow: 10px 24px 72px rgba(7, 98, 97, 0.05);
    border-radius: 15px;
    /* justify-items: center; */
}
.why-choose-us-card h1 {
    position: absolute;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
    line-height: 115px;
    color: #f55700;
    top: -3.8rem;
    left: 2rem;
}
.why-choose-us-card h3 {
    /* margin-top: 3rem; */
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    color: #301860;
}
.why-choose-us-card p {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    justify-self: center;
    color: #9f98ad;
    /* width: 80%; */
}
.lastCard {
    position: relative;
}
.lastCard img {
    position: absolute;
    width: 10rem;
    right: -3rem;
    top: -5rem;
    z-index: -10;
}
@media screen and (max-width: 1300px) {
    .why-choose-us-cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
        justify-items: center;
        gap: 0;
        row-gap: 4rem;
    }
    .lastCard img {
        position: absolute;
        width: 6rem;
        right: -2rem;
        top: -2rem;
        z-index: -10;
    }
}
@media screen and (max-width: 767px) {
    .why_Choose_us_Wrapper header h3 {
        font-size: 2.5rem;
    }
    .why_Choose_us_Wrapper header h3 img {
        width: 4rem;
        left: -1.7rem;
        top: -0.5rem;
    }
    .why-choose-us-card {
        width: 80%;
        /* max-width: fit-content; */
    }
    .why-choose-us-card h3 {
        font-size: 1.5rem;
    }
    .why-choose-us-card p {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 550px) {
    .why-choose-us-cards {
        grid-template-columns: 1fr;
    }
}
/* ---------------top courses------- */
.topCoursesWrapper {
    background: #0f4c5c;
    padding-bottom: 10rem;
    display: grid;
    gap: 4rem;
}
.topCoursesWrapper_header {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 6rem 0;
}
.topCoursesWrapper_header img {
    max-width: 20rem;
    position: absolute;
}
.topCoursesWrapper .bg_left {
    top: 0;
    left: 0;
}
.topCoursesWrapper .bg_right {
    top: 6rem;
    right: 0;
    height: 12rem;
}
.topCoursesWrapper_header > h3 {
    align-self: center;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    color: #ffffff;
}
.courses {
    display: flex;
    gap: 2.5rem;
    justify-content: center;
}
.course_item {
    /* display: ; */
    width: 23rem;
    background-color: white;
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}
.course_item img {
    width: 100%;
    margin: 0;
}
.content {
    display: grid;
    gap: 1.5rem;
    padding: 2rem 0;
    justify-items: center;
}
.content_header {
    display: flex;
    justify-content: space-between;
    padding: 0rem 0.5rem;
}
.content_header h3 {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.4rem;
    letter-spacing: 0.01em;
    color: #301860;
    width: 50%;
}
.price {
    display: grid;
    /* gap: 0.5rem; */
    align-items: center;
}
.price del {
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #a7a7a7;
}
.price span {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
    color: #f08458;
}
.content > p {
    justify-self: center;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.02em;
    text-align: justify;
    margin: 0 1rem;
    color: #9f98ad;
}
.content button {
    justify-self: center;

    background: #54215d;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: white;
    padding: 0.8rem 1rem;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    letter-spacing: 0.01em;
    border: none;
    cursor: pointer;
}
.topCoursesWrapper .loadmore {
    display: grid;
    /* justify-self: center; */
    position: relative;
    padding-bottom: 4rem;
}
.topCoursesWrapper .loadmore button {
    justify-self: center;
    background: #f55700;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: 0.01em;
    border: none;
    padding: 1rem 2rem;
    margin-top: 2rem;
    color: #ffffff;
    z-index: 10;
}
.topCoursesWrapper .loadmore img {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -3rem;
    overflow: hidden;
}
@media screen and (max-width: 1400px) {
    .courses {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(430px, 1fr));
        justify-items: center;
        gap: 1rem;
        row-gap: 3rem;
    }
    .topCoursesWrapper_header h3 {
        font-size: 3rem;
    }
}
.mentorShip {
    position: absolute;
}
@media screen and (max-width: 1200px) {
    .topCoursesWrapper_header img {
        max-width: 15rem;
    }
    .topCoursesWrapper_header .bg_right {
        max-width: 10rem;
    }
}
@media screen and (max-width: 768px) {
    .courses {
        grid-template-columns: 1fr;
    }
    .topCoursesWrapper_header {
        padding-bottom: 0rem;
    }
    .topCoursesWrapper_header h3 {
        font-size: 2rem;
    }
    .course_item {
        width: 80%;
    }
    .topCoursesWrapper_header img {
        max-width: 7rem;
    }
    .topCoursesWrapper_header .bg_right {
        max-width: 8rem;
        /* height: 10rem; */
    }
    .topCoursesWrapper .loadmore img {
        bottom: 0;
    }
}

/* ------------borwsebycategory---------- */

.BrowsebyCategories {
    background-color: white;
    padding: 5rem 0 5rem 0;
    display: grid;
    gap: 5rem;
    position: relative;
}
.mentor {
    position: absolute;
    background: #ffffff;
    box-shadow: 30px 4px 30px rgba(0, 0, 0, 0.05);
    border-radius: 75px;
    display: flex;
    width: 80%;
    height: 100%;
    align-items: center;
    justify-content: center;
    top: -7.5rem;
    justify-self: center;
    max-height: 12rem;
}
.mentor h3 {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
    width: 60%;

    color: #301860;
}
.BrowsebyCategories .mentor button {
    padding: 1rem 3rem;
    cursor: pointer;
}
.BrowsebyCategories header {
    display: grid;
    align-items: center;
    justify-items: center;
}
.BrowsebyCategories header h3 {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.3rem;
    text-align: center;
    color: #301860;
    z-index: 10;
}
.BrowsebyCategories header h3 img {
    width: 5rem;
    position: absolute;
    /* top: -2rem; */
    bottom: -1rem;
    left: -3rem;
    z-index: -10;
}

.BrowsebyCategories .subjects {
    /* padding: 0 4rem; */
    justify-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

    row-gap: 3rem;
}

.BrowsebyCategories .subjects .subject {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    width: fit-content;
    padding: 0 0.5rem;
    background: #ffffff;
    box-shadow: 30px 30px 54px rgba(4, 167, 163, 0.05);
    border-radius: 10px;
}
.BrowsebyCategories .subjects .subject h3 {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    letter-spacing: 0.01em;
    padding: 2rem 0rem;
    width: 8rem;
    color: #301860;
}
.BrowsebyCategories .subjects .subject .subjectIcon {
    padding: 1rem;
    display: flex;
    align-items: center;
    background: #f55700;
    border-radius: 10px;
}

.BrowsebyCategories .subjects .subject .subjectIcon img {
    width: 50px;
}
.BrowsebyCategories button {
    justify-self: center;
    background: #54215d;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    letter-spacing: 0.01em;
    color: #ffffff;
    padding: 1rem 2rem;
    border: none;
}
@media screen and (max-width: 1110px) {
    .mentor h3 {
        font-size: 3rem;
        text-align: center;
    }
    .BrowsebyCategories .mentor button {
        padding: 1rem;
    }
}
@media screen and (max-width: 767px) {
    .mentor {
        display: grid;
    }
    .mentor h3 {
        width: 100%;
        font-size: 2rem;
    }
    .BrowsebyCategories {
        /* padding: 15rem 0 0rem 0; */
        gap: 5rem;
    }
    .BrowsebyCategories header h3 {
        font-size: 2rem;
    }
    .BrowsebyCategories header h3 img {
        width: 4rem;
        left: -2.5rem;
        top: -1rem;
    }
    .BrowsebyCategories .subjects {
        grid-template-columns: 1fr;
    }
}
/* ------------------what our student says --------------- */
.studentsays {
    padding: 6rem 0 6rem 0;
    background: linear-gradient(180deg, #54215d 0%, #963365 100%);
    position: relative;
    display: grid;
    gap: 5rem;
    z-index: 10;
    overflow: hidden;
}
.student_right_bg {
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    /* height: 100%; */
    z-index: -10;
}
.studentcards {
    position: relative;
}
.student_left_bg {
    overflow: hidden;
    position: absolute;
    /* left: -20rem; */
    /* bottom: -40rem; */
    left: 0;
    bottom: -6rem;
    width: 80%;
    z-index: -10;
}
.studentsays header {
    display: grid;
    align-items: center;
    justify-items: center;
}
.studentsays header h3 {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.3rem;
    text-align: center;
    /* color: #301860; */
    color: white;
    z-index: 10;
}
.studentsays header h3 img {
    position: absolute;
    /* top: -2rem; */
    bottom: -1rem;
    left: -3rem;
    z-index: -10;
    width: 5rem;
}

.student_review {
    display: grid !important;
    padding: 1rem 0.5rem 0.5rem;
    margin-left: 3rem;
    width: 80% !important;
    background-color: white;
    gap: 1rem;
    box-shadow: 10px 14px 34px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    /* justify-items: center; */
}

.student_detail {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.student_review .student_detail img {
    width: 50px;
    height: 50px;
}
.student_review > img {
    justify-self: center;
    border-radius: 15px;
    width: 100%;
}
.student_review > p {
    text-align: left;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: #9f98ad;
    margin: 0;
    padding: 0.5rem 1rem;
    height: 240px;
    letter-spacing: 0.02em;
    /* text-align: justify; */
    /* margin: 0 1rem; */
    color: #9f98ad;
}
.slider {
    width: 95vw;
    height: auto;
    margin: auto;
    padding-bottom: 1rem;
}

@media screen and (max-width: 767px) {
    .student_review {
        margin-left: 0;
    }
    .studentsays header h3 {
        font-size: 2rem;
    }
    .studentsays header h3 img {
        width: 4rem;
        left: -2rem;
        top: -1.4rem;
    }
    .student_review {
        width: 90% !important;
    }
    .student_review > p {
        display: -webkit-box;

        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
/* @media screen and (max-width: 657px)


} */
.playstorewrapper {
    background-color: #fef8ed;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    gap: 10rem;
    padding: 10rem 0 5rem 0;
}
.playstore_leftside {
    display: grid;
    justify-items: center;
    align-items: center;
    gap: 2rem;
}
.playstore_leftside h3 {
    margin-left: 2rem;
    text-align: center;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    color: #301860;
}
.playstore_leftside a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: none;
    border-radius: 10px;
    color: white;
    background-color: black;
    padding: 1rem 1rem;
    text-decoration: none;
}
.playstore_leftside a p {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    letter-spacing: 0.02em;

    color: #ffffff;
}
.playstore_leftside a h5 {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    letter-spacing: 0.02em;

    color: #ffffff;
}
.playstorewrapper .rightside .phone {
    width: 100%;
}
@media screen and (max-width: 767px) {
    .playstorewrapper {
        grid-template-columns: 1fr;
        justify-items: center;
        padding: 5rem 0;
    }
    .playstore_leftside {
        gap: 3rem;
    }
    .playstore_leftside h3 {
        margin: 0;
        font-size: 3rem;
    }
    .playstorewrapper .rightside {
        display: none;
    }
}
/* ----------modal------------- */
.subject-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10000;
}
.subject-modal-content {
    gap: 2rem;
    opacity: 1;
    max-width: fit-content;

    width: 100%;
    /* margin-top: 7%;
    margin-left: 23%; */
    display: flex;
    align-items: center;
    background: #ffffff;
    position: relative;
    padding: 1rem;
}
.subject-modal-content form {
    display: grid;
    gap: 0.5rem;
}
.subject-formControl {
    display: grid;
    gap: 0.5rem;
}
.subject-formControl label {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    letter-spacing: 0.02em;
    color: #060606;
}
.subject-formControl select {
    width: 550px;
    height: 2.2rem;
    font-size: 1.1rem;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    border: 1.2px solid #a7a7a7;
    outline: none;
    text-indent: 1.5rem;
}
.input-error {
    border: 1px solid red !important;
}
.error {
    color: red;
}
.subject-modal-content form input[type="submit"] {
    color: white;
    font-size: 1.5rem;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    background-color: #00b8d9;
    border: none;
    border-radius: 1rem;
    padding: 0.5rem;
    margin-top: 0.5rem;
    /* justify-self: center; */
    cursor: pointer;
}
.subject-modal-content form input[type="submit"]:hover {
    color: #00b8d9;
    background-color: white;
    border: 1.2px solid #00b8d9;
}
@media screen and (max-width: 1000px) {
    .subject-modal {
        display: flex;
        align-items: center;
    }
    .subject-modal-content {
        margin: 1rem;
        gap: 1rem;
        height: fit-content;
        padding: 0.1rem;
    }
}
