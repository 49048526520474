/* ----------usernav ------- */
.nav-header {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 1rem;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
}
.nav-header img {
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
}
.nav-header p {
    font-size: 1.9rem;
    line-height: 39px;
    color: #301860;
}
.nav-header button {
    background: #54215d;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: white;
    border: none;
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
}
.user-navbar {
    border-top: 3px solid rgba(10, 10, 10, 0.07);
    display: grid;
    /* justify-items: center; */
    align-items: center;
    padding: 1rem 0;
}
.user-navbar a {
    text-decoration: none;
    display: flex;
    gap: 1rem;
    align-items: center;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    padding: 1rem 2rem;
}
.user-navbar button {
    display: flex;
    gap: 1rem;
    align-items: center;
    border: none;
    background-color: inherit;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    padding: 1rem 2rem;
    color: #301860;
    cursor: pointer;
}
.usernav-active {
    background: #f55700;
    color: white;
}
.usernav-inactive {
    color: #301860;
}
@media screen and (max-width: 1250px) {
    .user-navbar a,
    .user-navbar button {
        font-size: 1rem;
    }
    .nav-header img {
        height: 4rem;
    }
}
@media screen and (max-width: 950px) {
    .user-navbar a,
    .user-navbar button {
        font-size: 0.8rem;
    }
    .nav-header p {
        font-size: 1.3rem;
    }
}
@media screen and (max-width: 767px) {
    .user-navbar a span,
    .user-navbar button p {
        display: none;
    }
    .nav-header {
        gap: 0;
    }
    .nav-header p {
        font-size: 0.5rem;
    }
    .nav-header button {
        padding: 0.5rem 0.3rem;
        font-size: 0.7rem;
    }
    .nav-header img {
        height: 3rem;
    }
    .nav-icon {
        font-size: 1.3rem;
    }
    .user-navbar {
        gap: 1rem;
    }
    .user-navbar a {
        padding: 0.5rem 2rem;
    }
}
@media screen and (max-width: 500px) {
    .nav-header p,
    .nav-header button {
        display: none;
    }
}
/* --------subsciption-------- */

.subscription-wrapper {
    display: grid;
    grid-template-columns: 25% 75%;
}
.subscription-leftside {
    padding: 2rem 0.5rem 0 0.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    /* border: 1px solid red; */
}
.subscription-rightSide {
    background-color: #fef8ed;
    padding: 4rem 1rem 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.subscription-rightSide > h3 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 3.2rem;
    letter-spacing: 0.01em;
    color: #301860;
}
.subscription-nav {
    width: 60%;
    display: flex;
    padding: 0;
    padding-left: 3rem;
    border-bottom: 2px solid rgba(159, 152, 173, 0.38);
}
.subscription-nav button {
    /* border: none; */
    padding: 1rem;
    background-color: inherit;
    font-family: "Ubuntu";
    font-style: normal;
    font-size: 1.6rem;
    /* letter-spacing: 0.02em; */
    cursor: pointer;
}
.sub-btn-active {
    font-weight: 700;
    color: #f55700;
    border: none;
    border-bottom: 5px solid #f55700 !important;
}
.sub-btn-inactive {
    font-weight: 400;
    border: none;
    color: #9f98ad;
}
.subscription-subjects {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    row-gap: 2rem;
    column-gap: 1rem;
    cursor: pointer;
}
.subscription-subject {
    width: 300px;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.content {
    background-color: white;
    display: grid;
    gap: 0.5rem;
    padding: 1rem;
}
.content > p {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #301860;
}
.progress-wrapper {
    font-family: "Ubuntu";
    font-style: normal;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.progress-wrapper h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #9f98ad;
}
.progress,
.due-time {
    display: grid;
    gap: 0.5rem;
}
.progress-wrapper p {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #9f98ad;
}
.subscription-subject img {
    width: 100%;
}

@media screen and (max-width: 767px) {
    .subscription-wrapper {
        grid-template-columns: 15% 85%;
    }
    .nav-icon {
        size: 35;
    }
    .subscription-rightSide {
        padding: 4rem 0rem;
    }
    .subscription-rightSide > h3 {
        font-size: 2rem;
    }
    .subscription-nav {
        padding: 0;
        width: 100%;
    }
    .subscription-subjects {
        display: grid;
        justify-items: center;
    }
}
/* ----------library Content------- */
.library-content-wrapper {
    display: grid;
}
.libraryContent {
    background-color: #fef8ed;
    align-items: center;
    display: grid;
    /* justify-items: center; */
    gap: 2rem;
    padding: 5rem 20rem;
}
.library-video {
    width: 100%;
    height: 500px;
}
.libraryContent h3 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 2.3rem;
    letter-spacing: 0.01em;

    color: #301860;
}
.content-btns,
.content-btns button {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.content-btns button {
    cursor: pointer;
    gap: 0.5rem;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    border: none;
    letter-spacing: 0.01em;
    background: #54215d;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: #ffffff;
    padding: 0.6rem 2rem;
}
.libraryContent p {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 2rem;
    color: #9f98ad;
}
@media screen and (max-width: 1300px) {
    .libraryContent {
        padding: 5rem 15rem;
    }
}
@media screen and (max-width: 1000px) {
    .libraryContent {
        padding: 5rem;
    }
}
@media screen and (max-width: 768px) {
    .libraryContent {
        padding: 0.5rem;
    }
    .content-btns button {
        padding: 0.5rem 0.3rem;
        font-size: 1rem;
    }
    .library-video {
        min-height: 270px;
        height: 100%;
    }
}
/* ----------series -------------- */
.series-wrapper {
    background-color: #fef8ed;
}
.series {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 2rem;
    padding: 4rem 0;
}
.series-item {
    cursor: pointer;
    display: grid;
    grid-template-columns: 35% 38% 27%;
    align-items: center;
    width: 700px;
    /* height: 10rem; */
    gap: 2rem;
    background: #ffffff;
    border-radius: 10px;
}
.series-item img {
    width: 15rem;
    /* height: 10rem; */
}
.series-item-header {
    display: grid;
    gap: 1rem;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    letter-spacing: 0.02em;

    color: #301860;
}
@media screen and (max-width: 767px) {
    .series-item {
        grid-template-columns: 0.6fr 0.4fr;
        padding: 1rem;
        width: 90%;
    }
    .series-item img {
        display: none;
    }
    .series-item-header {
        font-size: 1rem;
    }
}
/* ---------------refer ------------- */
.refer-content,
.support-content {
    /* padding: 5rem 15rem; */
    display: grid;
    grid-template-columns: 25% 75%;
}
.refer-content-leftside,
.support-content-leftside {
    padding: 2rem 0.5rem 0 0.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}
.refer-content-rightside,
.support-content-rightside {
    background-color: #fef8ed;
    padding: 4rem 1rem 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.refer-content-rightside > h3,
.support-content-rightside > h3 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 3.5rem;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #301860;
}
.refer-code {
    position: relative;
    display: grid;
    justify-items: center;
    gap: 1rem;
    background: linear-gradient(180deg, #ff900e 0%, #f55700 87.5%);
}
.refer-bg {
    position: absolute;
    left: 0;
    width: 30%;
    left: 15px;
}
.invite-code {
    display: flex;
    justify-content: space-between;
    width: 15rem;
    padding: 0.5rem 1rem;
    align-items: center;
    border: 2px dashed white;
    position: relative;
}
.invite-code p {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: 0.01em;
    color: #ffffff;
}
.invite-code button {
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    background-color: #f55700;
    /* background-color: rgba(247, 246, 244, 0.2); */
    cursor: pointer;
}
.invite-code h5 {
    position: absolute;
    top: -2rem;
    font-size: 20px;
    width: 22rem;
    left: -2rem;
    background: white;
    /* box-shadow: ; */

    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.copy-icon {
    /* background-color: inherit; */
    width: 80%;
}
.social-icons {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.social-icons img {
    width: 60px;
    cursor: pointer;
}
.refer-code > button {
    background: #54215d;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: 0.01em;
    color: #ffffff;
    border: none;
    padding: 0.5rem 4rem;
    margin-bottom: 2rem;
}

@media screen and (max-width: 767px) {
    .refer-content-rightside,
    .support-content-rightside {
        padding: 1rem;
    }
    .refer-content-rightside > h3,
    .support-content-rightside > h3 {
        font-size: 2.3rem;
    }
    .refer-content,
    .support-content {
        grid-template-columns: 15% 85%;
    }
    .refer-code > img {
        width: 80%;
    }
    .refer-code .invite-code {
        width: 80%;
    }
    .refer-code .invite-code p {
        font-size: 18px;
    }
}
/* -----------------support ---------------- */
.support-content-rightside form {
    padding: 2rem 5rem;
    /* width: 70%; */
    max-width: 45rem;
    display: grid;
    gap: 1.5rem;
}
.profilepic {
    justify-self: center;
}
.profilepic img {
    height: 6rem;
}
.support-formControl {
    position: relative;
}
.support-formControl input:disabled {
    background-color: #dddddd;
}
.form-icon {
    position: absolute;
    left: 22px;
    top: 16px;
}
.input-error {
    border: 1px solid red;
}
.support-formControl input {
    width: 100%;
    height: 3.5rem;
    background: #ffffff;
    border-radius: 10px;
    outline: none;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 28px;
    text-indent: 4rem;
    letter-spacing: 0.01em;
    color: #301860;
    border: none;
}
.support-formControl textarea {
    font-family: "Ubuntu";
    padding: 1rem 0;
    width: 100%;
    font-size: 1.3rem;
    background: #ffffff;
    border-radius: 10px;
    outline: none;
    border: none;
    text-indent: 2rem;
    resize: none;
}
.support-content-rightside form button {
    margin-top: 2rem;
    justify-self: center;
    background: #54215d;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 0.6rem 2rem;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    letter-spacing: 0.01em;
    color: #ffffff;
    cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
@media screen and (max-width: 767px) {
    .support-content-rightside form {
        padding: 1rem;
    }
    .support-formControl input,
    .support-formControl textarea {
        width: 100%;
    }
}
/* ----edit profile------ */
.imageupload {
    align-self: center;
    position: relative;
    justify-self: center;
}

.profilepic_icon {
    position: absolute;
    bottom: 0;
    right: 1rem;
    color: white;
    background-color: #0c5ada;
    padding: 0.5rem;
    display: grid;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    border-radius: 10px;
}
.imageupload img {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
}
.imageupload > input {
    display: none;
}
