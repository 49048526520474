.loginWrapper {
    display: grid;
    /* grid-template-columns: 0.5fr 0.5fr; */
    overflow: hidden;
}

.login-leftside {
    background-image: url("/src/Components/Assets/images/login/login.svg");
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-position: right;

    background-color: #f3f3f3;
    font-family: "Ubuntu";
    font-style: normal;
    min-height: 95vh;
    height: 100%;
    overflow: hidden;
    letter-spacing: 0.02em;
    padding: 5rem 0 0 5rem;
    display: flex;
    flex-direction: column;
    gap: 7rem;
}
.signup-leftside {
    background-attachment: fixed;
    background-size: 60%;
    background-image: url("/src/Components/Assets/images/login/signup-leftside.svg");
}
.login-leftside h3 {
    font-weight: 700;
    font-size: 3rem;
    color: #54215d;
}
.login-header > p,
.login-header > h6 {
    font-weight: 400;
    font-size: 27px;
    color: #464646;
    margin-top: 0.5rem;
}
.login-header > h6 {
    width: 40%;
    /* text-align: r; */
    margin-top: 2rem;
    font-size: 1.7rem;
}
.login-header h6 span {
    text-align: center;
    color: #f55700;
    font-size: 1.6rem;
}

.login-form-control {
    display: grid;
    gap: 1.2rem;
}
.login-form-control label {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 1.7rem;
    letter-spacing: 0.02em;

    color: #060606;
}
.login-form-control input {
    background: #ffffff;
    border-radius: 39px;
    border: none;
    width: 27rem;
    outline: none;
    padding: 1rem 2rem;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    letter-spacing: 0.02em;
    /* color: #9f98ad; */
    text-indent: 1.5rem;
    height: 2.2rem;
}
.login-input {
    position: relative;
}
.login-input input[type="number"]::-webkit-inner-spin-button,
.login-input input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
.login-icon {
    position: absolute;
    left: 15px;
    top: 18px;
}
.login-icon-name {
    position: absolute;
    left: 18px;
    top: 24px;
}
.error {
    font-size: 1.2rem !important;
    margin-top: 0.5rem;
    color: red;
}
.input-error {
    border: 1px solid red !important;
}
.login-leftside form {
    display: grid;
    gap: 1rem;
}
.login-leftside form input[type="submit"] {
    cursor: pointer;
    background: #54215d;
    border-radius: 39px;
    width: fit-content;
    /* margin-left: 3rem; */
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    margin-top: 1rem;
    border: none;
    padding: 0.8rem 3rem;
    letter-spacing: 0.02em;
    color: #ffffff;
}
.signup-details {
    padding-top: 3rem;
    gap: 3rem;
}
@media screen and (max-width: 870px) {
    .login-leftside {
        background-image: none;
        gap: 3rem;
        padding: 5rem 1rem 1rem 1rem;
    }
    .login-leftside form {
        display: grid;
        /* justify-items: center; */
        padding: 0;
        margin: 0;
    }
    .login-leftside form input[type="submit"] {
        justify-self: center;
    }
    .login-form-control .login-input input {
        width: 100% !important;
        padding: 1rem 0;
        text-indent: 3rem;
        margin: 0;
    }
    .login-leftside h3 {
        font-size: 3rem;
    }

    .login-header > h6 {
        width: 100%;
    }
}
