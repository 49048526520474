.modal-spinner {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}
.modal-content-spinner {
    display: grid;
    justify-items: center;
    gap: 2rem;
    opacity: 1;
    max-width: 700px;
    width: 100%;
    padding: 2rem 0;
    position: relative;
}
.books-signup-container {
    padding: 0 !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.download-popup-btns {
    justify-self: center;
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1rem;
}
.download-popup-btns button {
    border: none;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    background-color: black !important;
    color: white;
    background-color: inherit;
    padding: 0.5rem;
    border-radius: 10px;
    text-align: left;
    cursor: pointer;
    height: 4rem;
    width: 100%;
    max-width: 13rem;
}
.download-popup-btns button p {
    color: white;
    font-size: 0.8rem;
    text-align: left;
}
.download-popup-btns button span {
    font-size: 1.3rem;
}
.download-popup-btns button img {
    width: 3rem;
    height: fit-content;
    background-color: transparent;
    background-blend-mode: color-burn;
    height: 90%;
}
.books-signup-container form h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */
    margin-bottom: 1rem;
    color: #081234;
}

.books-signup-container form {
    display: grid;
    gap: 1rem;
    padding: 1rem 2rem;
    height: fit-content;
    /* justify-self: center; */
    align-self: center;
}
.books-signup-container p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 0.95rem;

    color: #081234;
}
.books-signup-container form input {
    /* background: #d3d5da; */
    box-shadow: 1px 1px 2px rgba(230, 232, 238, 0.3),
        -1px -1px 2px rgba(192, 194, 198, 0.5),
        inset -3px 3px 6px rgba(192, 194, 198, 0.2),
        inset 3px -3px 6px rgba(192, 194, 198, 0.2),
        inset -3px -3px 6px rgba(230, 232, 238, 0.9),
        inset 3px 3px 8px rgba(192, 194, 198, 0.9);
    border-radius: 8px;
    height: 2.5rem;
    border: 1px solid #d3d5da;
    outline: none;
    text-indent: 1rem;
}
.books-signup-container form button {
    background: linear-gradient(135deg, #363637 0%, #0e0e0f 100%);
    box-shadow: -3px 3px 6px rgba(192, 194, 198, 0.2),
        3px -3px 6px rgba(192, 194, 198, 0.2),
        -3px -3px 6px rgba(230, 232, 238, 0.9),
        3px 3px 8px rgba(192, 194, 198, 0.9),
        inset 1px 1px 2px rgba(230, 232, 238, 0.3),
        inset -1px -1px 2px rgba(192, 194, 198, 0.5);
    border-radius: 45px;
    height: 2.5rem;
    color: white;
    font-size: 1.1rem;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
}
.books-signup-container form div {
    display: grid;
    gap: 0.5rem;
}

.books-signup-container form div p {
    font-size: 0.8rem;
}
@media screen and (max-width: 800px) {
    .download-popup-btns button span {
        font-size: 01rem;
    }
    .download-popup-btns button p {
        font-size: 0.7rem;
    }
    .books-signup-container {
        grid-template-columns: 1fr;
        justify-items: center;
    }
    .books-signup-container img {
        width: 250px;
    }
    .books-signup-container form {
        gap: 0.5rem;
        padding: 0.8rem;
    }
    .books-signup-container form h3 {
        font-size: 1.3rem;
        margin: 0;
        padding: 0;
    }
    .books-signup-container form p {
        font-size: 0.8rem;
    }
    .books-signup-container form div p {
        font-size: 0.7rem;
    }
    .books-signup-container button {
        width: fit-content;
        padding: 0 1rem;
        justify-self: center;
    }
}
