.heading {
    margin-top: 3rem;
}
.heading h1,
.heading p {
    text-align: center;
    font-size: 22px;
    font-family: "Jost", sans-serif;
}
.contactWrapper {
    display: grid;
    gap: 1.5rem;
    padding: 2rem 0;
}
.contact-details {
    justify-self: center;
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
    /* grid */
}
.address {
    margin-top: 1.5rem;
    display: grid;
    gap: 0.2rem;
    justify-items: center;
    text-align: center;
}
.address h4 {
    color: #00b0f0;
}
.address h3 {
    font-size: 1.2rem;
    color: #4f4f4f;
}
.address p {
    text-align: center;
    color: #4f4f4f;
    font-size: 1.1rem;
    line-height: 1.4rem;
}
.contact-us {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.contact-us h3 {
    color: #4f4f4f;
    font-family: "Jost", sans-serif;
    font-size: 1.4rem;
    letter-spacing: 0.05em;
}
.contact-us form {
    margin-left: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.contact-us form input:disabled {
    background-color: gray !important;
}
.form-control {
    display: grid;
    gap: 0.8rem;
}
.form-control label {
    color: #4f4f4f;
    font-size: 1.2rem;
    font-weight: 530;
}
.form-control input,
.form-control textarea {
    font-size: 1.1rem;
    text-indent: 1rem;
    width: 85%;
    padding: 0.8rem 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
}
.form-control input:focus,
.form-control textarea:focus {
    box-shadow: 0 0 5px red;
}
.error {
    color: red;
}
.form-control textarea {
    resize: none;
}
.contact-us form input[type="submit"] {
    color: white;
    background-color: #4caf50;
    width: fit-content;
    border: none;
    font-size: 1.1rem;
    padding: 0.6rem 1rem;
    border-radius: 10px;
    cursor: pointer;
}
.map {
    width: 100%;
}
@media screen and (max-width: 1055px) {
    .contact-details {
        grid-template-columns: 1fr;
    }
}
