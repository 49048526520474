.video_player {
    width: 100%;
    height: 500px;
    padding: 2rem 0;
}
@media screen and (max-width: 700px) {
    .video_player {
        height: 80vw;
    }
}
