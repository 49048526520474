.policy-wrapper {
    padding: 2rem 4rem;
    display: grid;
    gap: 2rem;
}
.policy-wrapper h1,
.policy-wrapper h2 {
    font-weight: bold;
    text-align: center;
    font-family: "Jost", sans-serif;
    color: #4f4f4f;
    font-size: 2rem;
    padding: 2rem 0;
}
.policy {
    display: grid;
    gap: 0.5rem;
}
.policy h2 {
    padding: 0 0 0;
    text-align: left;
    font-size: 1.3rem;
    font-weight: 1000 !important;
}
.policy ul li,
.policy p {
    text-align: justify;
    font-size: 1.1rem;
    color: #4f4f4f;
}
.policy ul {
    list-style-type: circle;
}

@media screen and (max-width: 500px) {
    .policy-wrapper {
        padding: 1.4rem;
    }
}
